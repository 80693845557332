import {
  IInputPlaceSelect,
} from '../../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraMa: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Açailândia',
    value: 'ACAILANDIA',
  },
  {
    id: 2,
    label: 'Bacabal',
    value: 'BACABAL',
  },
  {
    id: 3,
    label: 'Caxias',
    value: 'CAXIAS',
  },
  {
    id: 4,
    label: 'Imperatriz',
    value: 'IMPERATRIZ',
  },
  {
    id: 5,
    label: 'São Luís',
    value: 'SAO_LUIS',
  },
  {
    id: 6,
    label: 'Timon',
    value: 'TIMON',
  },
];



export default CitiesFullBraMa;
