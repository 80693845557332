import {
  IInputPlaceSelect,
} from '../../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraTo: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Araguaína',
    value: 'ARAGUAINA',
  },
  {
    id: 2,
    label: 'Araguatins',
    value: 'ARAGUATINS',
  },
  {
    id: 3,
    label: 'Colinas do Tocantins',
    value: 'COLINAS_DO_TOCANTINS',
  },
  {
    id: 4,
    label: 'Dianópolis',
    value: 'DIANOPOLIS',
  },
  {
    id: 5,
    label: 'Gurupi',
    value: 'GURUPI',
  },
  {
    id: 6,
    label: 'Miracema do Tocantins',
    value: 'MIRACEMA_DO_TOCANTINS',
  },
  {
    id: 7,
    label: 'Palmas',
    value: 'PALMAS',
  },
  {
    id: 8,
    label: 'Paraíso do Tocantins',
    value: 'PARAISO_DO_TOCANTINS',
  },
  {
    id: 9,
    label: 'Porto Nacional',
    value: 'PORTO_NACIONAL',
  },
  {
    id: 10,
    label: 'Tocantinópolis',
    value: 'TOCANTINOPOLIS',
  },
];



export default CitiesFullBraTo;
