import {
  IInputPlaceSelect,
} from '../../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraGo: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Anápolis',
    value: 'ANAPOLIS',
  },
  {
    id: 2,
    label: 'Aparecida de Goiânia',
    value: 'APARECIDA_DE_GOIANIA',
  },
  {
    id: 3,
    label: 'Catalão',
    value: 'CATALAO',
  },
  {
    id: 4,
    label: 'Goiânia',
    value: 'GOIANIA',
  },
  {
    id: 5,
    label: 'Luziânia',
    value: 'LUZIANIA',
  },
  {
    id: 6,
    label: 'Rio Verde',
    value: 'RIO_VERDE',
  },
  {
    id: 7,
    label: 'Senador Canedo',
    value: 'SENADOR_CANEDO',
  },
];



export default CitiesFullBraGo;
