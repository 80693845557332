import {
  IInputPlaceSelect,
} from '../../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraCe: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Caucaia',
    value: 'CAUCAIA',
  },
  {
    id: 2,
    label: 'Crato',
    value: 'CRATO',
  },
  {
    id: 3,
    label: 'Fortaleza',
    value: 'FORTALEZA',
  },
  {
    id: 4,
    label: 'Iguatu',
    value: 'IGUATU',
  },
  {
    id: 5,
    label: 'Itapipoca',
    value: 'ITAPIPOCA',
  },
  {
    id: 6,
    label: 'Juazeiro do Norte',
    value: 'JUAZEIRO_DO_NORTE',
  },
  {
    id: 7,
    label: 'Maracanaú',
    value: 'MARACANAU',
  },
  {
    id: 8,
    label: 'Quixadá',
    value: 'QUIXADA',
  },
  {
    id: 9,
    label: 'Sobral',
    value: 'SOBRAL',
  },
  {
    id: 10,
    label: 'Tianguá',
    value: 'TIANGUA',
  },
];



export default CitiesFullBraCe;
