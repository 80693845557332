import {
  IInputPlaceSelect,
} from '../../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraAp: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Amapá',
    value: 'AMAPA',
  },
  {
    id: 2,
    label: 'Calçoene',
    value: 'CALCOENE',
  },
  {
    id: 3,
    label: 'Laranjal do Jari',
    value: 'LARANJAL_DO_JARI',
  },
  {
    id: 4,
    label: 'Macapá',
    value: 'MACAPA',
  },
  {
    id: 5,
    label: 'Mazagão',
    value: 'MAZAGAO',
  },
  {
    id: 6,
    label: 'Oiapoque',
    value: 'OIAPOQUE',
  },
  {
    id: 7,
    label: 'Santana',
    value: 'SANTANA',
  },
  {
    id: 8,
    label: 'Tartarugalzinho',
    value: 'TARTARUGALZINHO',
  },
  {
    id: 9,
    label: 'Vitória do Jari',
    value: 'VITORIA_DO_JARI',
  },
];



export default CitiesFullBraAp;
