import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';



export const worldCountriesArray: Array<IInputSelect> = [
  { id: 1, label: 'Brasil', value: 'BRA' },
];



export default worldCountriesArray;
