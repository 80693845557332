import {
  IInputPlaceSelect,
} from '../../../../apis/interfaces/App/input-select-place.interface';



const CitiesFullBraEs: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Cachoeiro de Itapemirim',
    value: 'CACHOEIRO_DE_ITAPEMIRIM',
  },
  {
    id: 2,
    label: 'Colatina',
    value: 'COLATINA',
  },
  {
    id: 3,
    label: 'Guarapari',
    value: 'GUARAPARI',
  },
  {
    id: 4,
    label: 'Linhares',
    value: 'LINHARES',
  },
  {
    id: 5,
    label: 'Serra',
    value: 'SERRA',
  },
  {
    id: 6,
    label: 'Vila Velha',
    value: 'VILA_VELHA',
  },
  {
    id: 7,
    label: 'Vitória',
    value: 'VITORIA',
  },
];



export default CitiesFullBraEs;
