import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export interface IProps {
  backgroundColor?: string;
}



export const EnvBadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  right: 0;
  width: 70px;
  height: 70px;
  opacity: 0.5;
  pointer-events: none;
  z-index: 99999;
`;

export const EnvBadgeView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -25%;
  top: 10%;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.gray};
  transform: rotate(45deg);
`;

export const EnvBadgeText = styled.p`
  color: ${Colors.white};
  text-align: center;
  font-size: 10px;
`;
